import { combineReducers } from 'redux';
import { reducer as FormReducer } from 'redux-form';
import exampleReducer from '../../components/example/exampleReducer';
import {reducer as toastrReducer} from 'react-redux-toastr';
import asyncReducer from '../../components/async/asyncReducer';
import QuestionsReducer from '../../components/questions/QuestionsReducer';
import RulesReducer from '../../components/rules/RulesReducer';
import ResourcesReducer from '../../components/resources/ResourcesReducer';
import UserLookupReducer from "components/UserLookup/store/Reducer";

const rootReducer = combineReducers({
    form: FormReducer,
    example: exampleReducer,
		toastr: toastrReducer,
    async: asyncReducer,
    questions: QuestionsReducer,
    userLookup: UserLookupReducer,
    rulesReducer: RulesReducer,
    resourcesReducer: ResourcesReducer
});

export default rootReducer;
