export const LIST_RESOURCES = 'LIST_RESOURCES';
export const RESOURCES_ADD = 'RESOURCES_ADD';
export const RESOURCES_EDIT = 'RESOURCES_EDIT';
export const CURRENT_RESOURCES = 'CURRENT_RESOURCES';
export const SEARCH_RESOURCE_PARAM = 'SEARCH_RESOURCE_PARAM';
export const IS_UPDATED = 'IS_UPDATED';
export const resource_url = '/api/resources';
export const resources_list_url = `${resource_url}/list`;
export const resources_add_url = `${resource_url}/add`;
export const resources_edit_url = `${resource_url}/edit`;
export const resources_delete_url = `${resource_url}/delete`;