import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Loadable from 'react-loadable';

const Loading = () => <div>Loading...</div>;
const Home = Loadable({
  loader: () => import('./Home'),
  loading: Loading
});
const ExampleComponent = Loadable({
  loader: () => import('./example/Example'),
  loading: Loading
});
const UserLookup = Loadable({
	loader: () => import('./UserLookup'),
	loading: Loading
});
const Questions = Loadable({
  loader: () => import('./questions/Questions'),
  loading: Loading
});
const ContentReasons = Loadable({
  loader: () => import('./rules/ContentReasons'),
  loading: Loading
});
const Rules = Loadable({
  loader: () => import('./rules/Rules'),
  loading: Loading
});
const Resources = Loadable({
  loader: () => import('./resources/Resources'),
  loading: Loading
});
const Login = Loadable({
  loader: () => import('./Login'),
  loading: Loading
});
const Logout = Loadable({
  loader: () => import('./Logout'),
  loading: Loading
});
const Restrict = Loadable({
  loader: () => import('./Restrict'),
  loading: Loading
});

class App extends Component {
  render() {
    return (
      <div>
        <div className="container" id="layout-content">
          <Switch>
            <Route
              exact={true}
              path="/"
              render={() =>
	              <>
		              <Home {...this.props} />
	              </>
              }
            />
          </Switch>
          <Route
            path="/(.+)"
            render={() => {
              return (
                <div>
                  <div className="main">
                    <Switch>
                      <Route path="/examples" component={ExampleComponent} />
                      <Route path="/userLookup" component={UserLookup} />
                      <Route path="/questions" component={Questions} />
                      <Route path="/contentReasons" component={ContentReasons} />
                      <Route path="/rules" component={Rules} />
                      <Route path="/resources" component={Resources} />
                      <Route path="/login" component={Login} />
                      <Route path="/logout" component={Logout} />
                      <Route path="/restrict" component={Restrict} />
                    </Switch>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    );
  }
}

export default App;
