import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from './app/store/configureStore';
import { BrowserRouter } from 'react-router-dom';
import './index.scss';
import App from './components/App';
import * as serviceWorker from './serviceWorker';
const store = configureStore();
const rootEl = document.getElementById('root');

let render = () => {
    ReactDOM.render(<Provider store={store}><BrowserRouter><App /></BrowserRouter></Provider>, rootEl);
};

if (module.hot) {
    module.hot.accept('./components/App', () => {
        setTimeout(render);
    });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
