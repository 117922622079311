import { createStore, applyMiddleware } from 'redux'; //, compose
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import rootReducer from '../reducers/rootReducer';


const configureStore = (preloadedState) => {
    const middlewares = [thunk];
    const middlewareEnhancer = applyMiddleware(...middlewares);
    const storeEnhancers = [middlewareEnhancer];
    /*const composeEnhancers =
		typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
		  // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
		}) : compose;
	const enhancer = composeEnhancers(
	  ...storeEnhancers
    );*/
    /*
	const enhancer = composeWithDevTools/compose(
		...storeEnhancers
	);
    */
	const enhancer = composeWithDevTools(
		...storeEnhancers
	);
    const store = createStore(
        rootReducer,
        preloadedState,
        enhancer
    );

    
    if (process.env.NODE_ENV !== 'production') {
        if (module.hot) {
            module.hot.accept('../reducers/rootReducer', () => {
                const newRootReducer = require('../reducers/rootReducer').default;
                store.replaceReducer(newRootReducer);
            });
        }
    }

    return store;
};


export default configureStore;