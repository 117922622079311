export const rules_url = '/api/rules';
export const rules_list_url = `${rules_url}/list`;
export const rules_add_url = `${rules_url}/add`;
export const rules_edit_url = `${rules_url}/edit`;
export const rules_deletes_url = `${rules_url}/deletes`;
export const LIST_RULES = 'LIST_RULES';
export const ADD_RULE_SHOW = 'ADD_RULE_SHOW';
export const ADD_PARAM = 'ADD_PARAM';
export const EDIT_IDS = 'EDIT_IDS';
export const DELETE_IDS = 'DELETE_IDS';
export const DELETE_IDS_EMPTY = 'DELETE_IDS_EMPTY';
export const SEARCH_PARAM = 'SEARCH_PARAM';
export const IS_DELETED = 'IS_DELETED';
export const IS_UPDATED = 'IS_UPDATED';
export const IS_ADDED = 'IS_ADDED';