import { LIST_CATEGORIES, EXTEND_CATEGORIES, LIST_QUESTIONS, CURRENT_QUESTION, SEARCH_QUESION_PARAM, IS_UPDATED } from './QuestionsConstants';

const initialState = {
	categories: null,
	list_questions: [],
	current_question: null,
	search_param: {
		APP: undefined,
		QUESTION: '',
		PAGE: '',
		ANSWER: ''
	},
	is_updated: false,
};

const QuestionsReducer = (state = initialState, action) => {
	switch (action.type) {
		case IS_UPDATED:
			state = {
				...state,
				is_updated: action.payload
			};
			break;
		case EXTEND_CATEGORIES:
			let extend_categories = [...state.categories];
			if (!extend_categories) extend_categories = [];
			extend_categories.push(action.payload);
			state = {
				...state,
				categories: extend_categories
			}
			break;
		case LIST_CATEGORIES:
			state = {
				...state,
				categories: action.payload
			}
			break;
		case LIST_QUESTIONS:
			state = {
				...state,
				list_questions: action.payload
			};
			break;
		case CURRENT_QUESTION:
			state = {
				...state,
				current_question: action.payload
			};
			break;
		case SEARCH_QUESION_PARAM:
			state = {
				...state,
				search_param: {
					...state.search_param,
					...action.payload
				}
			};
			break;
		default:
			break;
	}

	return state;
};

export default QuestionsReducer;