import {
  ASYNC_ACTION_START,
  ASYNC_ACTION_FINISH,
  ASYNC_ACTION_ERROR
} from './asyncConstants';

const initialState = {
  loading: false,
  error: false,
  error_message: null
};

const AsyncReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ASYNC_ACTION_START:
      state = {
        ...state,
        loading: true,
        error: false,
        error_message: null
      };
      break;
    case ASYNC_ACTION_FINISH:
      state = {
        ...state,
        loading: false,
        error: false,
        error_message: null
      };
      break;
    case ASYNC_ACTION_ERROR:
      state = {
        ...state,
        loading: false,
        error: true,
        error_message: action.payload
      };
      break;
    default:
      break;
  }

  return state;
};

export default AsyncReducer;
