import { USER_ANSWERS } from './Constants';

const createReducer = (initialState, handlers) => (
  state = initialState,
  action
) => {
  if (handlers.hasOwnProperty(action.type)) {
    return handlers[action.type](state, action);
  } else {
    return state;
  }
};

const initialState = {
  userList: []
};

const UserLookupReducer = createReducer(initialState, {
  [USER_ANSWERS]: (state, action) => {
    const userList = action.result.data;
    return {...state, userList};
  }
});

export default UserLookupReducer;
