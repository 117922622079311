import {
  LIST_RULES,
  ADD_RULE_SHOW,
  ADD_PARAM,
  EDIT_IDS,
  DELETE_IDS,
  DELETE_IDS_EMPTY,
  SEARCH_PARAM,
  IS_DELETED,
  IS_UPDATED,
  IS_ADDED
} from './RulesConstant';

const initialState = {
  data: [],
  result: null,
  pageNum_rsView: 0,
  showAdd: false,
  showSearch: true,
  add_param: {
    APP: undefined,
    RULE_ID: '',
    CRITERIA: '',
    RESULT: '',
    error_message: {
      APP: '',
      RULE_ID: '',
      CRITERIA: '',
      RESULT: ''
    }
  },
  edit_ids: {},
  delete_ids: {},
  search_param: {
    APP: { value: '' },
    RULE_ID: { value: '' },
    CRITERIA: ''
  },
  is_updated: false
};

const RulesReducer = (state = initialState, action) => {
  switch (action.type) {
    case IS_DELETED:
      state = {
        ...state,
        is_deleted: action.payload
      };
      break;
    case IS_UPDATED:
      state = {
        ...state,
        is_updated: action.payload
      };
      break;
    case IS_ADDED:
      state = {
        ...state,
        is_added: action.payload
      };
      break;
    case LIST_RULES:
      state = {
        ...state,
        data: action.payload.result,
        result: action.payload
      };
      break;
    case 'LIST_RULES_PAGE':
      state = {
        ...state,
        pageNum_rsView: action.payload.result
      };
      break;
    case 'SEARCH_RULE_SHOW':
      state = {
        ...state,
        showSearch: action.payload
      };
      break;
    case ADD_RULE_SHOW:
      state = {
        ...state,
        showAdd: action.payload
      };
      break;
    case ADD_PARAM:
      state = {
        ...state,
        add_param: action.payload
      };
      break;
    case EDIT_IDS:
      const edit_ids = { ...state.edit_ids };
      edit_ids[action.payload.id] = action.payload.val;
      state = {
        ...state,
        edit_ids
      };
      break;
    case DELETE_IDS:
      const delete_ids = { ...state.delete_ids };
      delete_ids[action.payload.id] = action.payload.val;
      if (!action.payload.val) {
        delete delete_ids[action.payload.id];
      }
      state = {
        ...state,
        delete_ids
      };
      break;
    case DELETE_IDS_EMPTY:
      state = {
        ...state,
        delete_ids: {}
      };
      break;
    case SEARCH_PARAM:
      state = {
        ...state,
        search_param: {
          ...state.search_param,
          ...action.payload
        }
      };
      break;
    default:
      break;
  }

  return state;
};

export default RulesReducer;
