import {
  LIST_RESOURCES,
  CURRENT_RESOURCES,
  SEARCH_RESOURCE_PARAM,
  IS_UPDATED
} from './ResourcesConstants';

const initialState = {
  list_resources: {
    rows: [],
    pageNum_rsView: 0
  },
  current_resources: null,
  resourceKey: '',
  resourceValue: '',
  search_param: {
    COUNTRY: undefined,
    LANGUAGE: undefined,
    RESOURCE_KEY: '',
    RESOURCE_VALUE: ''
  },
  is_updated: false
};

const ResourcesReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case IS_UPDATED:
      state = {
        ...state,
        is_updated: action.payload
      };
      break;
    case LIST_RESOURCES:
      state = {
        ...state,
        list_resources: action.payload
      };
      break;
    case CURRENT_RESOURCES:
      state = {
        ...state,
        current_resources: action.payload
      };
      break;
    case 'SEARCH_RESOURCE_KEY':
      state = {
        ...state,
        resourceKey: action.payload
      };
      break;
    case 'SEARCH_RESOURCE_VALUE':
      state = {
        ...state,
        resourceValue: action.payload
      };
      break;
    case SEARCH_RESOURCE_PARAM:
      state = {
        ...state,
        search_param: {
          ...state.search_param,
          ...action.payload
        }
      };
      break;
    default:
      break;
  }

  return state;
};

export default ResourcesReducer;
